// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  my_url: 'https://leash.maplage.dev/',
  widget_uri: 'https://peak.maplage.dev/widget/',
  graphql_uri: 'https://peak.maplage.dev/graphql/',
  uploads_uri: 'https://peak.maplage.dev/uploads/',
  exports_uri: 'https://peak.maplage.dev/exports/',
  ws_uri: 'wss://peak.maplage.dev/graphql',
  keycloak: {
    KEYCLOAK_CLIENT_ID: 'iris-interactive',
    KEYCLOAK_BASE_URL: 'https://auth.preprod.maplage.info/auth',
    KEYCLOAK_REALM_NAME: 'dock',
  },
  atlas: {
    ATLAS_BASE_URL: 'https://atlas.microsoft.com/search/fuzzy/json',
    ATLAS_SUBSCRIPTION_KEY: 'rdeaQcF3Ap8iPmjs0SMJCZbcLxiDFVre1yX9prqC5mU',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
